import { Badge } from "@mui/material"

export const NotificationBadge = ({ count }: { count: number }) => {
    return (
        <Badge
            badgeContent={count}
            sx={{ margin: '0 14px' }}
            color="primary">
        </Badge>
    );
}
